.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  min-width: 100px;
  background-color: #1f2833;
  /* background-color: white; */
  z-index: 1;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product > button {
  background: white;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
  margin-top: 10px;
  color: #01283a;
  cursor: pointer;
}
.product__info {
  color: white;
  height: 100px;
  margin-bottom: 0px;
}
.product__price {
  margin-top: 5px;
}

.product__rating {
  display: flex;
}
