.payment {
  background-color: white;
}

.payment__container > h1 {
  text-align: center;
  padding: 10px;
  font-weight: 400;
  background-color: rgb(234, 237, 237);
  border-bottom: 1px solid lightgray;
}

.payment__container > h1 a {
  text-decoration: none;
}

.payment__section {
  display: flex;
  padding: 20px;
  margin: 0 20px;
  border-bottom: 1px solid lightgray;
}

.payment__title {
  flex: 0.2;
}

.payment__address,
.payment__items,
.payment__details {
  flex: 0.8;
}

.payment__details > form {
  max-width: 400px;
}

.payment__details > h3 {
  padding-bottom: 20px;
}

.payment__details > form > div > button {
  background: #1f2833;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  font-weight: bolder;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: white;
  cursor: pointer;
}
