.login {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login__logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 350px;
  margin-right: auto;
  margin-left: auto;
}

.login__container {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}

.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login__container > form > h5 {
  margin-bottom: 5px;
}

.login__container > form > input {
  height: 20px;
  margin-bottom: 10px;
  width: 100%;
}

.login__signinButton {
  background: #1f2833;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: white;
  cursor: pointer;
}

.login__registerButton {
  background-color: darkgray;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: darkgray;
  color: #1f2833;
  cursor: pointer;
}
